import React from "react";
import BannerCommonContent from "../components/BannerCommonContent";
import CommonComponent from "../components/CommonContent";
import { Seo } from "../components/seo";
import Layout from "../components/layout";
const TermsAndCondition = () => {
  const title = "Terms & Conditions";
  const bannerContent = [
    'Welcome to Octet Design Studio! <br /> These terms and conditions outline the rules and regulations for the use of Octet Design Studio’s Website, <br /> located at <a href="https://octet.design/">https://octet.design/.</a>',
    "By accessing this website we assume you accept these terms and conditions. Do not continue to use Octet Design Studio if you do not agree to take all of the terms and conditions stated on this page.",
    "The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and all Agreements: “Client”, “You” and “Your” refers to you, the person log on this website and compliant to the Company’s terms and conditions. “The Company”, “Ourselves”, “We”, “Our” and “Us”, refers to our Company. “Party”, “Parties”, or “Us”, refers to both the Client and ourselves. All terms refer to the offer, acceptance and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner for the express purpose of meeting the Client’s needs in respect of the provision of the Company’s stated services, in accordance with and subject to, prevailing law of India. Any use of the above terminology or other words in the singular, plural, capitalisation and/or he/she or they, are taken as interchangeable and therefore as referring to same.",
  ];
  const content = [
    {
      title: "Cookies",
      text: [
        "We employ the use of cookies. By accessing Octet Design Studio, you agreed to use cookies in agreement with the Octet Design Studio’s Privacy Policy.",
        "Most interactive websites use cookies to let us retrieve the user’s details for each visit. Cookies are used by our website to enable the functionality of certain areas to make it easier for people visiting our website. Some of our affiliate/advertising partners may also use cookies.",
      ],
    },
    {
      title: "License",
      text: [
        "Unless otherwise stated, Octet Design Studio and/or its licensors own the intellectual property rights for all material on Octet Design Studio. All intellectual property rights are reserved. You may access this from Octet Design Studio for your own personal use subjected to restrictions set in these terms and conditions.",
        "You must not:",
      ],
      list: {
        listItem: [
          "Republish material from Octet Design Studio",
          "Sell, rent or sub-license material from Octet Design Studio",
          "Reproduce, duplicate or copy material from Octet Design Studio",
          "Redistribute content from Octet Design Studio",
        ],
        listText: "This Agreement shall begin on the date hereof.",
      },
      text2: [
        "Parts of this website offer an opportunity for users to post and exchange opinions and information in certain areas of the website. Octet Design Studio does not filter, edit, publish or review Comments prior to their presence on the website. Comments do not reflect the views and opinions of Octet Design Studio, its agents and/or affiliates. Comments reflect the views and opinions of the person who post their views and opinions. To the extent permitted by applicable laws, Octet Design Studio shall not be liable for the Comments or for any liability, damages or expenses caused and/or suffered as a result of any use of and/or posting of and/or appearance of the Comments on this website.",
        "Octet Design Studio reserves the right to monitor all Comments and to remove any Comments which can be considered inappropriate, offensive or causes breach of these Terms and Conditions.",
        "You warrant and represent that:",
      ],
      list2: {
        listItem: [
          "You are entitled to post the Comments on our website and have all necessary licenses and consents to do so;",
          "The Comments do not invade any intellectual property right, including without limitation copyright, patent or trademark of any third party;",
          "The Comments do not contain any defamatory, libellous, offensive, indecent or otherwise unlawful material which is an invasion of privacy",
          "The Comments will not be used to solicit or promote business or custom or present commercial activities or unlawful activity.",
        ],
        listText:
          "You hereby grant Octet Design Studio a non-exclusive license to use, reproduce, edit and authorize others to use, reproduce and edit any of your Comments in any and all forms, formats or media.",
      },
    },
    {
      title: "Hyperlinking To Our Content",
      text: [
        "The following organizations may link to our Website without prior written approval:",
      ],
      list: {
        listItem: [
          "Government agencies;",
          "Search engines;",
          "News organizations;",
          "Online directory distributors may link to our Website in the same manner as they hyperlink to the Websites of other listed businesses; and",
          "System-wide Accredited Businesses except soliciting non-profit organizations, charity shopping malls, and charity fundraising groups which may not hyperlink to our Web site.",
        ],
        listText:
          "These organizations may link to our home page, to publications or to other Website information so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products and/or services; and (c) fits within the context of the linking party’s site.",
      },
      text2: [
        "We may consider and approve other link requests from the following types of organizations:",
      ],
      list2: {
        listItem: [
          "commonly-known consumer and/or business information sources;",
          "dot.com community sites;",
          "online directory distributors;",
          "internet portals;",
          "accounting, law and consulting firms; and",
          "educational institutions and trade associations.",
        ],
        listText:
          "We will approve link requests from these organizations if we decide that: (a) the link would not make us look unfavourably to ourselves or to our accredited businesses; (b) the organization does not have any negative records with us; (c) the benefit to us from the visibility of the hyperlink compensates the absence of Octet Design Studio, and (d) the link is in the context of general resource information.",
      },
      text3: [
        "These organizations may link to our home page so long as the link: (a) is not in any way deceptive; (b) does not falsely imply sponsorship, endorsement or approval of the linking party and its products or services; and (c) fits within the context of the linking party’s site.",
        "If you are one of the organizations listed in paragraph 2 above and are interested in linking to our website, you must inform us by sending an e-mail to Octet Design Studio. Please include your name, your organization name, contact information as well as the URL of your site, a list of any URLs from which you intend to link to our Website, and a list of the URLs on our site to which you would like to link. Wait 2-3 weeks for a response.",
        "Approved organizations may hyperlink to our Website as follows:",
      ],
      list3: {
        listItem: [
          "By use of our corporate name; or",
          "By use of the uniform resource locator being linked to; or",
          "By use of any other description of our Website being linked to that makes sense within the context and format of content on the linking party’s site.",
        ],
        listText:
          "No use of Octet Design Studio’s logo or other artwork will be allowed for linking absent a trademark license agreement.",
      },
    },
    {
      title: "IFrames",
      text: [
        "Without prior approval and written permission, you may not create frames around our Webpages that alter in any way the visual presentation or appearance of our Website.",
      ],
    },
    {
      title: "Content Liability",
      text: [
        "We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libellous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.",
      ],
    },
    {
      title: "Your Privacy",
      text: ['Please read <a href="/privacy-policy/">Privacy Policy</a>'],
    },
    {
      title: "Reservation Of Rights",
      text: [
        "We reserve the right to request that you remove all links or any particular link to our Website. You approve to immediately remove all links to our Website upon request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuously linking to our Website, you agree to be bound to and follow these linking terms and conditions.",
      ],
    },
    {
      title: "Removal Of Links From Our Website",
      text: [
        "If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links but we are not obligated to or so or to respond to you directly.",
        "We do not ensure that the information on this website is correct, we do not warrant its completeness or accuracy; nor do we promise to ensure that the website remains available or that the material on the website is kept up to date.",
      ],
    },
    {
      title: "Disclaimer",
      text: [
        "To the maximum extent permitted by applicable law, we exclude all representations, warranties and conditions relating to our website and the use of this website. Nothing in this disclaimer will:",
      ],
      list: {
        listItem: [
          "limit or exclude our or your liability for death or personal injury;",
          "limit or exclude our or your liability for fraud or fraudulent misrepresentation;",
          "limit any of our or your liabilities in any way that is not permitted under applicable law; or",
          "exclude any of our or your liabilities that may not be excluded under applicable law.",
        ],
        listText:
          "The limitations and prohibitions of liability set in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer, including liabilities arising in contract, in tort and for breach of statutory duty.",
      },
      text2: [
        "As long as the website and the information and services on the website are provided free of charge, we will not be liable for any loss or damage of any nature.",
      ],
    },
  ];
  return (
    <Layout>
      <BannerCommonContent bannerContent={bannerContent} title={title} />
      <CommonComponent content={content} />
    </Layout>
  );
};
export default TermsAndCondition;

export const Head = () => (
  <Seo
    title="Terms and Conditions - Octet Design Studio"
    description="Read about all the terms and conditions before opting our services from the website. For more details, you can call us on +918511188998"
  />
);
